import React, { useState, useRef, useEffect } from "react";
import Context from "../../Context";
import {
  Container,
  Typo,
  Flex,
  Badge,
  ContentContainer,
  Breadcrumb,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  InputBox,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";
import { useToast } from "../../components/ui";
import constant from "../../constants";
// import {
//   getThaiDateAndTimeFormat,
//   getThaiDateFormat,
//   statusColor,
//   constant.statusTranslated,
//   statuses,
//   validate,
// } from "../../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shortDate, shortTime } from "../../util";
import { Spinner } from "reactstrap";
import {
  FaTimes,
  FaCheckCircle,
  FaHourglassHalf,
  FaTimesCircle,
  FaMoneyBillAlt,
} from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa6";

export const Affilate = () => {
  const { profileState, callApi, updateProfileState } = Context();
  const { toast } = useToast();
  const [state, setState] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(null);
  const [withdrawHistory, setWithdrawHistory] = useState(false);
  useEffect(() => {
    getUserReferrer();
  }, []);

  const getUserReferrer = async () => {
    // const { totalOrder, totalUser, orders, users } = await callApi(
    //   "referralPoints-getUserReferrer"
    // );

    // const withdrawHistory = await callApi("referralPoints-getWithdrawTransactionByUserId")

    await Promise.all([
      callApi("affiliates-getAffiliateUser"),
      callApi("affiliates-getAffiliateIncome"),
      callApi("affiliates-getTransactionByUserId", {
        userId: profileState?.userId,
      }),
    ]).then(([{ totalUser }, { totalLotto }, withdrawHistory]) => {
      setWithdrawHistory(
        withdrawHistory.data
          .filter((x) => x.type === "withdraw")
          .sort((a, b) => b.createdAt - a.createdAt)
      );
      setState({
        totalUser,
        totalLotto,
      });
    });
  };

  const submitWithdraw = async () => {
    const { status, message } = await callApi(
      "affiliates-createWithdrawTransaction",
      {
        withdraw: withdrawAmount,
      }
    );
    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> ถอนเงินสำเร็จ
          </div>
        ),
        description: message || "-",
      });
      updateProfileState();
      getUserReferrer();
      setWithdrawAmount(null);
      setShowDialog();
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> ถอนเงินไม่สำเร็จ
          </div>
        ),
        description: message || "-",
      });
    }
  };

  return (
    <Container>
      <AlertDialog open={showDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>ถอนเงิน</AlertDialogTitle>

            <div className="flex flex-col items-start">
              <p className="text-sm font-semibold mt-2">จำนวน (บาท)</p>{" "}
              <Input
                className="text-center mb-2"
                type="number"
                value={withdrawAmount}
                placeholder="100"
                onChange={(e) => {
                  setWithdrawAmount(e.target.value);
                }}
              />
              <AlertDialogFooter className="grid h-10 grid-cols-2 w-full">
                <button
                  className=" px-4 py-0  hover:opacity-90 font-semibold border-0 text-white bg-[#D60B0B] rounded-xl"
                  onClick={() => setShowDialog(false)}
                >
                  ยกเลิก
                </button>
                <button
                  className="px-4 py-0 bg-[#003A9E] disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-700 hover:opacity-90 text-white font-semibold rounded-xl"
                  disabled={
                    withdrawAmount < 100 ||
                    withdrawAmount > (profileState.buyCash || 0)
                  }
                  onClick={() => submitWithdraw()}
                  // disabled={true}
                >
                  ยืนยัน
                </button>
              </AlertDialogFooter>
              <p className="text-sm font-semibold mt-12 mb-1">วิธีรับรายได้</p>
              <p className="text-xs list-disc">
                1. คัดลอกลิงก์และแชร์ให้เพื่อนไปยังช่องทางต่าง ๆ เช่น Line
                Facebook
              </p>
              <p className="text-xs mb-3">
                2. ชวนเพื่อนมาสมัครสมาชิกใหม่ผ่านลิงก์ของลูกค้า
              </p>
              <p className="text-sm font-semibold mb-1">
                เงื่อนไขการรับรายได้ชวนเพื่อน
              </p>
              <p className="text-xs ">
                1. เพื่อนของลูกค้าต้องเป็น " สมาชิกใหม่ " เท่านั้น
              </p>
              <p className="text-xs">
                2. เพื่อนของลูกค้าต้องสั่งซื้อลอตเตอรี่ขั้นต่ำ 1 ใบภายในงวดนั้น
              </p>
              <p className="text-xs">
                3. ลูกค้าต้องยอดสะสมขั้นต่ำ 100 บาทขึ้นไปจึงจะสามารถถอนได้
              </p>
              <p className="flex gap-1 text-xs mt-3 font-medium">
                <span className="text-red-500">*</span>{" "}
                บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงค่าแนะนำ และเงื่อนไข
                โดยไม่แจ้งให้ทราบล่วงหน้า
              </p>
            </div>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>
      <Breadcrumb
        data={[{ path: "/", name: "หน้าแรก" }, { name: "รายได้ชวนเพื่อน" }]}
      />
      <p
        className="my-9 font-semibold text-navy text-center text-xl"
        color="navy"
        // textAlign="center"
      >
        รายได้ชวนเพื่อน
      </p>
      {/* totalincome */}
      <div className="flex flex-col shadow-sm justify-between w-full bg-white rounded-2xl p-4 gap-4">
        <p className="text-xl font-medium">รายได้สะสม </p>
        <p className="text-right text-3xl font-semibold">
          {new Intl.NumberFormat().format(profileState.buyCash || 0)} บาท
        </p>

        <button
          // disabled={(profileState.buyCash || 0) < 100}
          className="text-gray-50 font-semibold p-2 bg-gradient-to-r from-[#002C7B] to-[#2065C4] rounded-xl hover:bg-none hover:text-navy border-2 hover:border-navy duration-150 disabled:bg-none disabled:opacity-60 disabled:bg-slate-400 disabled:border-slate-400 disabled:text-white disabled:cursor-not-allowed"
          onClick={() => setShowDialog(true)}
        >
          ถอนเงิน
        </button>

        <div>
          <p className="text-sm font-semibold mb-1">วิธีรับรายได้</p>
          <p className="text-xs list-disc">
            1. คัดลอกลิงก์และแชร์ให้เพื่อนไปยังช่องทางต่าง ๆ เช่น Line Facebook
          </p>
          <p className="text-xs mb-3">
            2. ชวนเพื่อนมาสมัครสมาชิกใหม่ผ่านลิงก์ของลูกค้า
          </p>
          <p className="text-sm font-semibold mb-1">
            เงื่อนไขการรับรายได้ชวนเพื่อน
          </p>
          <p className="text-xs ">
            1. เพื่อนของลูกค้าต้องเป็น " สมาชิกใหม่ " เท่านั้น
          </p>
          <p className="text-xs">
            2. เพื่อนของลูกค้าต้องสั่งซื้อลอตเตอรี่ขั้นต่ำ 1 ใบภายในงวดนั้น
          </p>

          <p className="text-xs">
            3. ลูกค้าต้องยอดสะสมขั้นต่ำ 100 บาทขึ้นไปจึงจะสามารถถอนได้
          </p>

          {/* <p className="text-xs font-medium">
            - จะได้รับค่าตอบเเทนใบละ 2 บาท
            - เรทรายได้งวด{" "}
            {
              <span className="text-xs font-bold ">
                {new Date(configs.roundDate).toLocaleDateString("th-TH", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </span>
            }{" "}
            ใบละ 2 บาท
          </p> */}
          <p className="flex gap-1 text-xs mt-3 font-medium">
            <span className="text-red-500">*</span>{" "}
            บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงค่าแนะนำ และเงื่อนไข
            โดยไม่แจ้งให้ทราบล่วงหน้า
          </p>
        </div>
      </div>
      <div className="flex my-3 flex-col  overflow-hidden justify-between shadow-sm w-full bg-white rounded-2xl p-4">
        <div>
          <p className="text-sm font-medium text-gray-400">ลิ้งชวนเพื่อน</p>
          <p
            className="text-sm xl:text-xl text-center py-2 truncate font-medium break-all"
            id="btnCopyLink"
          >
            {`${constant.webConfigs.customerUrl + "HT/" + profileState.userId}`}
          </p>
        </div>
        <button
          className="bg-[#FFCD00] flex items-center justify-center gap-2 px-4 py-2 mt-2 font-semibold rounded-xl duration-150 border-2 border-[#FFCD00] hover:bg-transparent hover:text-[#FFCD00]"
          onClick={() => {
            const copyText = document.getElementById("btnCopyLink").innerHTML;
            navigator.clipboard.writeText(copyText);
            // navigator.clipboard.writeText(link[1].value);
            toast({
              className: "bg-[rgb(59,176,101)]",
              title: (
                <div className="flex gap-2 items-center">
                  <FaCheckCircle /> คัดลอกสำเร็จ
                </div>
              ),
            });
          }}
        >
          <FaRegCopy className="h-4" /> คัดลอก
        </button>
      </div>
      {/* sumary */}
      <div className="flex flex-col w-full md:flex-row gap-3 my-3">
        <div className="flex flex-col shadow-sm hover:scale-105 duration-150 justify-between text-white bg-[rgb(59,176,101)] rounded-2xl p-4 w-full md:w-1/2 gap-4">
          <p className="text-xl font-medium">สมัครสมาชิกสำเร็จ</p>

          {state ? (
            <p className="text-center text-3xl font-semibold">
              {(state.totalUser || 0).toLocaleString()}
            </p>
          ) : (
            <Spinner className="text-center self-center text-white" />
          )}

          <p className="text-right text-lg font-medium">ลิงก์</p>
        </div>
        {/* <div className="flex flex-col shadow-sm hover:scale-105 duration-150 justify-between text-white bg-[#37beb0] rounded-2xl p-4  w-full md:w-1/2 gap-4">
          <p className="text-xl font-medium">ออเดอร์ทั้งหมด</p>

          {state ? (
            <p className="text-center text-3xl font-semibold">
              {(state.totalOrder || 0).toLocaleString()}
            </p>
          ) : (
            <Spinner className="self-center text-[#FFCD00]" />
          )}

          <p className="text-right text-lg font-medium">ออเดอร์</p>
        </div> */}
        {/* <div className="flex flex-col shadow-sm hover:scale-105 duration-150 justify-between bg-white rounded-2xl p-4  w-full md:w-1/2 gap-4">
          <p className="text-xl font-medium">ออเดอร์งวดปัจจุบัน</p>

          {state ? (
            <p className="text-center text-3xl font-semibold">
              {(state.currentOrderSize || 0).toLocaleString()}
            </p>
          ) : (
            <Spinner className="self-center text-[#FFCD00]" />
          )}

          <p className="text-right text-lg font-medium">ออเดอร์</p>
        </div> */}
        <div className="flex flex-col hover:scale-105 shadow-sm duration-150 justify-between bg-[#FFCD00] rounded-2xl p-4  w-full md:w-1/2 gap-4">
          <p className="text-xl font-medium">ยอดขายจากการชวนเพื่อนงวดนี้</p>

          {state ? (
            <p className="text-center text-3xl font-semibold">
              {(state.totalLotto || 0).toLocaleString()}
            </p>
          ) : (
            <Spinner className="text-center self-center text-white" />
          )}

          <p className="text-right text-lg font-medium">ใบ</p>
        </div>
      </div>

      <div className="flex my-3 flex-col  overflow-hidden justify-between shadow-sm w-full bg-white rounded-2xl p-4">
        <p className="text-xl font-medium">ประวัติการถอนเงิน </p>
        {!withdrawHistory ? (
          <div className="flex justify-center items-center h-48">
            <Spinner className="" />
          </div>
        ) : withdrawHistory.length > 0 ? (
          <>
            <Table className={`mt-3`}>
              <TableHeader>
                <TableRow>
                  <TableHead className="text-center">เวลา</TableHead>
                  <TableHead className="text-center">ไอดี</TableHead>

                  <TableHead className={`text-center w-fit`}>จำนวน</TableHead>
                  <TableHead className={`text-center w-[100px]`}>
                    สถานะ
                  </TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {withdrawHistory.map((transaction, index) => {
                  return (
                    <TableRow key={index} className={`align-top`}>
                      <TableCell className="font-medium text-center">
                        <p className="text-xs">
                          {shortDate(transaction?.createdAt)} เวลา{" "}
                          {shortTime(transaction?.createdAt)}
                        </p>
                      </TableCell>
                      <TableCell className="text-center font-bold">
                        {transaction.shortId}
                      </TableCell>
                      <TableCell className="text-center font-bold text-green-500">
                        {transaction.amount.toLocaleString()}
                      </TableCell>
                      <TableCell className="text-center font-bold text-green-500">
                        <Badge
                          background={constant.statusColor[transaction.status]}
                        >
                          {transaction.status === "pending"
                            ? "รออนุมัติ"
                            : transaction.status === "approved"
                            ? "อนุมัติ"
                            : "ไม่อนุมัติ"}
                        </Badge>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        ) : (
          <div className="h-24 flex justify-center items-center">
            <p className="text-gray-400 text-xs">ไม่พบประวัติการถอนเงิน</p>
          </div>
        )}
      </div>
      {/* transaction history */}
      {/* <div className="flex flex-col mt-3 shadow-sm w-full bg-white rounded-2xl p-4 gap-4">
        <p className="text-2xl font-medium">
          ประวัติรายได้งวดปัจจุบัน{" "}
          <span className=" font-medium text-yellow-500">| </span>
          <span className="text-sm font-medium text-gray-400">
            {new Date(configs.roundDate).toLocaleDateString("th-TH", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
        </p>
      </div>
      <div className="mb-24">
        {state ? (
          state.history.length > 0 ? (
            state.history
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((order, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify-between items-end shadow-sm mx-auto md:w-full xl:w-11/12 bg-white my-3 rounded-2xl p-4 gap-4 duration-150 hover:scale-105"
                  >
                    <div className="flex flex-col">
                      <p className="text-sm font-medium">เลขที่อ้างอิง</p>
                      <p className="text-2xl font-bold">
                        {order.shortId || order.userId}
                      </p>
                      <p className="text-sm font-medium text-gray-400">{`${
                        shortDate(order.createdAt) +
                        " | " +
                        shortTime(order.createdAt)
                      }`}</p>
                    </div>
                    {order.shortId ? (
                      <div className="flex flex-col ">
                        <p className="text-2xl font-bold">{order.size} ใบ</p>
                        <p className="text-xs font-semibold text-green-500 mr-2">
                          +{order.size * 2} บาท
                        </p>
                      </div>
                    ) : (
                      <div className="self-center">
                        <p className="text-md text-green-500 font-medium mr-2">
                          + สมัครสมาชิกสำเร็จ
                        </p>
                      </div>
                    )}
                  </div>
                );
              })
          ) : (
            <div className="flex items-center justify-center w-full h-60">
              <p className="text-gray-400 font-semibold">ไม่พบออเดอร์</p>
            </div>
          )
        ) : (
          <div className="flex items-center justify-center w-full h-60">
            <Spinner />
          </div>
        )}
      </div> */}
    </Container>
  );
};
